import React, { SetStateAction, useEffect, useState, Dispatch, MouseEventHandler, useContext } from 'react';
import { ChromelessPlayer, Player, FullscreenOptions } from 'theoplayer';
import { XMLParser } from 'fast-xml-parser';
import PlayerButton from 'features/radio/component/player/PlayerButton';
import { CloseIcon } from 'components/Icons';
import { IonIcon, isPlatform } from '@ionic/react';
import { expandOutline } from 'ionicons/icons';
import { PlayerContext, PlayerContextType } from 'features/radio/PlayerContext';
import { stopAllAudio } from 'lib/utils';
import 'theoplayer/ui.css';
import clsx from 'clsx';

type TheoPlayerCustomProps = {
    src?: string;
    adSrc?: string;
    type: string;
    autoplay?: boolean;
    init?: ((p: ChromelessPlayer) => void) | Dispatch<SetStateAction<ChromelessPlayer | undefined>>;
    state?: 'playing' | 'paused';
    className?: string;
    thumbnailUrl?: string;
    controls?: {
        fullscreen?: boolean;
    };
    audio?: boolean;
    mainAudio?: boolean;
    controlsEnabled?: boolean;
};

export default function TheoPlayerCustom({
    init,
    src,
    adSrc,
    type,
    autoplay,
    state,
    className,
    thumbnailUrl,
    controls,
    audio = false,
    mainAudio = false,
    controlsEnabled = false,
}: TheoPlayerCustomProps) {
    const theoplayerRef = React.useRef(null);

    const [player, setPlayer] = useState<undefined | Player>();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const { playerState, playerDispatch } = useContext<PlayerContextType | null>(PlayerContext) as PlayerContextType;

    const handleClickPlayPause = () => {
        if (!player) return;
        if (player.paused) {
            stopAllAudio();
            playerDispatch({ type: 'PAUSE' });
            player?.play();
        } else {
            player?.pause();
        }
    };

    async function updatePlayer() {
        if (!player) return;
        player.poster = thumbnailUrl || '';
        player.preload = 'auto';

        let adPromise = Promise.resolve([]);
        if (adSrc) {
            // @ts-ignore
            adPromise = fetch(adSrc)
                .then((response) => response.text())
                .then(async (ad) => {
                    const xmlToObject = new XMLParser({
                        attributeNamePrefix: '',
                        ignoreAttributes: false,
                    }).parse(ad);

                    let adBreaks = xmlToObject['vmap:VMAP']['vmap:AdBreak'];
                    adBreaks = Array.isArray(adBreaks) ? adBreaks : [adBreaks];

                    const filteredAds: { sources: string; timeOffset: string }[] = await Promise.all(
                        adBreaks.map(async (adBreak: any) => {
                            try {
                                const sources = adBreak['vmap:AdSource']['vmap:AdTagURI'];
                                const { timeOffset } = adBreak;
                                const response = await fetch(sources['#text']);
                                const data = await response.text();

                                if (!data.includes('ERRORCODE')) {
                                    return { sources: sources['#text'], timeOffset };
                                }
                                return null; // Return null for ads with ERRORCODE
                            } catch (error) {
                                console.error('Error fetching ad data:', error);
                                return null; // Return null for errors
                            }
                        }),
                    );

                    return filteredAds.filter((filteredAd) => filteredAd !== null); // Remove null values
                });
        }
        const ads = await adPromise;
        try {
            player.source = {
                sources: [
                    {
                        src,
                        type,
                    },
                ],
                ads,
            };
        } catch {
            player.source = {
                sources: [
                    {
                        src,
                        type,
                    },
                ],
            };
        }
        if (autoplay) {
            playerDispatch({ type: 'PAUSE' });
            player.play();
        }
    }

    const handleClickPlayPauseMainAudio = () => {
        updatePlayer().then(() => {
            setTimeout(() => {
                if (!player) return;

                if (playerState.isPlaying) {
                    stopAllAudio();
                    player?.play();
                } else {
                    player?.pause();
                }
            }, 200);
        });
    };

    const handleClickPlayPauseMainAudioButton = () => {
        if (!player) return;

        if (player.paused) {
            playerDispatch({ type: 'PLAY' });
            stopAllAudio();
            player?.play();
        } else {
            playerDispatch({ type: 'PAUSE' });

            player?.pause();
        }
    };

    useEffect(() => {
        if (isPlatform('cordova')) {
            if (isFullScreen) {
                window.screen.orientation.unlock();
            }

            if (!isFullScreen) {
                // @ts-ignore
                window.screen.orientation.lock('portrait');
            }
        }
    }, [isFullScreen]);

    useEffect(() => {
        if (playerState.isPlaying) {
            player?.pause();
        }
    }, [playerState.isPlaying]);

    useEffect(() => {
        if (!state || !player) return;
        if (state === 'playing') {
            playerDispatch({ type: 'PAUSE' });
            player.play();
        } else if (state === 'paused') {
            player.pause();
        }
    }, [state, player]);

    useEffect(() => {
        if (!theoplayerRef.current) return;
        const configuration = {
            autoplay: true,
            controls: true,
            responsive: true,
            allowNativeFullscreen: true,
            license: process.env.REACT_APP_THEOPLAYER_LICENSE ?? '',
            libraryLocation: '/theocopy/',
            privacy: {
                resistFingerprinting: true,
            },
        };
        const p = new Player(theoplayerRef.current, configuration);

        p.addEventListener('play', () => {
            setIsPlaying(true);
        });
        p.addEventListener('pause', () => {
            setIsPlaying(false);
        });
        p.addEventListener('dimensionchange', (e) => {
            const documentWidth = document.documentElement.clientWidth;
            setIsFullScreen(e.width > documentWidth - 5 && e.width < documentWidth + 5);
        });
        setPlayer(p);
        updatePlayer();
        if (init) {
            init(p);
        }
    }, []);

    useEffect(() => {
        updatePlayer();
    }, [src, type, player, autoplay]);

    useEffect(() => {
        if (mainAudio) {
            handleClickPlayPauseMainAudio();
        }
    }, [playerState.isPlaying]);

    const handleClickFullScreen: MouseEventHandler = (e) => {
        e.stopPropagation();
        if (!player?.element) return;
        if (isPlatform('cordova')) {
            // @ts-ignore
            window.screen.orientation.unlock();
        }

        player.presentation.requestMode('fullscreen');
    };

    const handleClickExitFullScreen: MouseEventHandler = (e) => {
        e.stopPropagation();
        if (!player?.element) return;
        if (isPlatform('cordova')) {
            // @ts-ignore
            window.screen.orientation.lock('portrait');
        }

        player.presentation.requestMode('inline');
    };

    const getIsPlayingState = () => {
        if (mainAudio) {
            return playerState.isPlaying;
        }
        return isPlaying;
    };

    return !audio ? (
        <div className={`relative group/videoplayer cursor-pointer ${className || ''}`}>
            <div
                className={clsx(
                    'theoplayer-skin',
                    controlsEnabled && 'theoplayer-container video-js THEOplayer',
                    !controlsEnabled && '[&_.vjs-control-bar]:!hidden [&_.vjs-big-play-button]:!hidden',
                    'relative h-full w-full [&_.theo-close-button]:!hidden [&_.vjs-big-play-button]:!hidden',
                )}
                ref={theoplayerRef}
            >
                <div
                    className="absolute h-[calc(100%-70px)] w-full"
                    onClick={handleClickPlayPause}
                />
                <div
                    className={`absolute top-2 right-2 z-[50] ${isFullScreen ? 'opacity-100' : 'opacity-0'}`}
                    style={{ visibility: 'visible' }}
                >
                    <button onClick={handleClickExitFullScreen}>
                        <CloseIcon />
                    </button>
                </div>
                <div
                    style={{ visibility: 'visible' }}
                    className={`absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-transparent z-20 transition-all ${
                        !isPlaying && isFullScreen ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    <PlayerButton
                        type={!isPlaying ? 'play' : 'pause'}
                        onClick={handleClickPlayPause}
                    />
                </div>
            </div>

            {!isFullScreen ? (
                <div
                    className={`absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-transparent z-20 transition-all duration-300 ${
                        !isPlaying ? 'opacity-100' : 'opacity-0 '
                    }`}
                >
                    <PlayerButton
                        type={!isPlaying ? 'play' : 'pause'}
                        onClick={handleClickPlayPause}
                    />
                </div>
            ) : (
                ''
            )}

            {controls?.fullscreen && !controlsEnabled ? (
                <button
                    className="absolute bottom-1 right-2 bg-transparent z-20 transition-all opacity-0 max-md:opacity-100  group-hover/videoplayer:opacity-100"
                    onClick={handleClickFullScreen}
                >
                    <IonIcon icon={expandOutline} />
                </button>
            ) : (
                ''
            )}
        </div>
    ) : (
        <div
            className={clsx(
                '[&_.vjs-control-bar]:!hidden [&_.vjs-big-play-button]:!hidden',
                className,
                'relative group/videoplayer cursor-pointer',
            )}
            onClick={() => (mainAudio ? handleClickPlayPauseMainAudioButton() : handleClickPlayPause())}
        >
            <div
                className="h-[45px] w-[45px] [&_.theo-close-button]:!hidden [&_.vjs-tech]:!hidden"
                ref={theoplayerRef}
            >
                <PlayerButton
                    type={!getIsPlayingState() ? 'play' : 'pause'}
                    onClick={() => {}}
                />
            </div>
        </div>
    );
}
